/*-----------------------------------------------------------------------
/ project - header
----------------------------------------------------------------------- */

/* p-header
----------------------------------------------------------------- */
.p-header {
  display: flex;
  box-sizing: border-box;

  @include mq-pc-tablet {
    padding-right: 24px;
    height: 72px;
    border-bottom: solid 1px #CDCDD3;
  }

  @include mq-mobile {
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  &__logoCol {
    @include mq-pc-tablet {
      margin: 0 20px 0 20px;
    }

    @include mq-mobile {
      margin-left: vw(16);
      order: 1;
    }
  }

  &__searchCol {
    display: flex;
    align-items: center;

    @include mq-pc-tablet {
      flex: 1;
      margin: 0 24px 0 0;
    }

    @include mq-mobile {
      width: 100%;
      order: 3;
      padding: vw(8) vw(10);
      border-top: solid 1px #CDCDD3;
      background-color: #fff;
    }
  }

  &__contactCol {
    @include mq-pc-tablet {
      display: flex;
      align-items: center;
      margin-right: 8px;
    }

    @include mq-mobile {
      position: fixed;
      z-index: 100;
      width: vw(56);
      height: vw(56);
      overflow: hidden;
      right: vw(10);
      bottom: vw(10);
      background-color: $color-red;
      background-image: url(../images/icon-mail-white.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: vw(24);
      border-radius: 100%;

      a {
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
  }

  &__userCol {
    display: flex;
    align-items: center;

    @include mq-pc-tablet {
      margin-left: 8px;
    }

    @include mq-mobile {
      height: vw(56);
      margin-right: vw(12);
      order: 2;
    }
  }
}


/* p-header-logo
----------------------------------------------------------------- */
.p-header-logo {
  display: flex;
  align-items: center;
  height: 100%;

  &__icon {
    @include mq-pc-tablet {
      padding: 10px 0;
      margin-right: 15px;
    }

    @include mq-mobile {
      height: vw(40);
      margin-right: vw(10);

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }



    &--talent {
      @include mq-pc-tablet {
        width: 86px;
        padding: 0;
      }
    }
  }

  &__label {
    @include mq-pc-tablet {
      width: 140px;
      height: 40px;
    }

    @include mq-mobile {
      width: vw(120);
      height: vw(35);
    }
  }
}


/* p-header-searchBox
----------------------------------------------------------------- */
.p-header-searchBox {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  background: #EFEFF0;
  border-radius: 4px;

  @include mq-pc-tablet {
    height: 48px;
  }

  &__inputText {
    display: block;
    width: 100%;
    padding: 12px 0;
    margin: 0 12px;
    appearance: none;
    border: none;
    background: none;

    &:focus {
      outline: none;
    }
  }

  &__btn {
    overflow: hidden;
    background-color: inherit;
    background-image: url(../images/icon-search.svg);
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: center;
    appearance: none;
    border: none;
    cursor: pointer;
    text-indent: 500px;
    white-space: nowrap;

    @include mq-pc-tablet {
      width: 60px;
      height: 48px;
    }

    @include mq-mobile {
      width: vw(40);
      height: vw(40);
    }
  }
}


/* p-header-userMenu
----------------------------------------------------------------- */
.p-header-userMenu {
  position: relative;

  &__icon {
    background: #1F1E26;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    user-select: none;
    cursor: pointer;

    @include mq-pc-tablet {
      width: 48px;
      height: 48px;
      line-height: 48px;
      font-size: 20px;
    }

    @include mq-mobile {
      width: vw(40);
      height: vw(40);
      line-height: vw(40);
      font-size: vw(20);
    }
  }

  &__menu {
    display: none;
    position: absolute;
    top: 52px;
    right: 0;
    z-index: 100;
    background-color: #fff;
    border: solid 1px #CDCDD3;
    white-space: nowrap;

    @include mq-pc-tablet {}

    @include mq-mobile {}

    .p-header-userMenu.is-active & {
      display: block;
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    .menu__item {
      padding: 12px 16px;
    }

    .menu__item+.menu__item {
      border-top: solid 1px #CDCDD3;
    }
  }
}