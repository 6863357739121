/*-----------------------------------------------------------------------
/ project - searchRes
----------------------------------------------------------------------- */

/* p-searchRes
----------------------------------------------------------------- */
.p-searchRes {}

.p-searchRes-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include mq-mobile {
    justify-content: space-between;
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;

    @include mq-pc-tablet {
      width: 240px;
      margin: 0 20px 40px;
    }

    @include mq-mobile {
      width: 48%;
      margin-bottom: vw(24);
    }

    .item__label {
      order: 2;
    }

    .item__thumbnail {
      order: 1;
      display: block;
    }

    .item__cnt {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;
      background-color: #23B78F;
      border-radius: 100%;
      text-align: center;
      font-weight: bold;
      line-height: 1;
      overflow: hidden;
      pointer-events: none;

      @include mq-pc-tablet {
        width: 48px;
        height: 48px;
      }

      @include mq-mobile {
        width: vw(48);
        height: vw(48);
      }

      span {
        @include mq-pc-tablet {
          font-size: 20px;
        }

        @include mq-mobile {
          font-size: vw(20);
        }
      }

      &::after {
        content: "ITEM";
        display: block;
        font-weight: normal;
        font-size: 10px;

        @include mq-pc-tablet {
          margin-top: 3px;
        }

        @include mq-mobile {
          margin-top: vw(3);
        }
      }
    }

    .item__img {
      display: block;
      overflow: hidden;
      border-radius: 100%;
      background-color: #c1c1c1;

      @include mq-pc-tablet {
        width: 240px;
        height: 240px;
      }

      @include mq-mobile {
        width: 47.5vw;
        height: 47.5vw;
      }

      img {
        user-select: none;
        pointer-events: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .item__label {
      text-align: center;
      font-weight: bold;

      @include mq-pc-tablet {
        margin-top: 8px;
        font-size: 18px;
      }

      @include mq-mobile {
        margin-top: vw(8);
        font-size: vw(16);
      }
    }
  }
}