/*-----------------------------------------------------------------------
/ project - footer
----------------------------------------------------------------------- */

/* p-footer
----------------------------------------------------------------- */
.p-footer {
  position: relative;
}

.p-footer-contact {
  position: relative;
  z-index: 2;

  @include mq-pc-tablet {
    padding: 64px 40px;
    text-align: center;
  }

  @include mq-mobile {
    padding: vw(32);
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(../images/footer-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: .5;
  }

  &__inner {
    position: relative;
    z-index: 2;
  }

  &__title {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: .02em;

    @include mq-pc-tablet {
      margin-bottom: 24px;
      font-size: 36px;
    }

    @include mq-mobile {
      margin-bottom: vw(16);
      text-align: center;
      font-size: vw(32);
    }
  }

  &__text {
    line-height: 1.75;

    @include mq-pc-tablet {
      margin-bottom: 56px;
      font-size: 20px;
    }

    @include mq-mobile {
      margin-bottom: vw(32);
    }
  }

  &__contactBtn {
    .c-btn-contactMail-footer {

      @include mq-mobile {
        width: 100%;
        font-size: vw(18);
      }
    }
  }
}

.p-footer-menu {
  @include mq-pc-tablet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 40px;
  }

  @include mq-mobile {
    padding: vw(26) vw(32);
    font-size: vw(12);
  }

  &__logo {
    @include mq-mobile {
      margin-bottom: vw(24);
    }
  }

  &__nav {
    line-height: 1.1;

    @include mq-mobile {
      margin-bottom: vw(24);
    }

    ul {
      @include mq-pc-tablet {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    li {
      @include mq-pc-tablet {
        padding: 0 2em;
      }

      &+li {
        @include mq-pc-tablet {
          border-left: solid 1px #fff;
        }


        @include mq-mobile {
          margin-top: vw(14);
        }
      }
    }
  }

  &__copy {}
}