/*-----------------------------------------------------------------------
/ Foundation - base
----------------------------------------------------------------------- */

html {
  height: 100%;
  font-size: 10px;
  touch-action: manipulation;
}

body {
  height: 100%;
  background: $color-background;
  color: $color-textBase;
  line-height: 1.4;
  font-size: 1.4rem;
  font-family: $font-family-primary;
  font-feature-settings: "tnum";
  -webkit-font-smoothing: antialiased !important;
  // word-wrap: break-word;
  // word-break: break-all;
  // white-space: normal;
  // font-feature-settings: "palt";
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;

  @include mq-mobile {
    font-size: 16px;
  }
}

a {
  cursor: pointer;
}

a:link {
  color: $color-primarylink;
  text-decoration: none;
}

a:visited {
  color: $color-primarylink;
  text-decoration: none;
}

a:hover {
  color: $color-primarylink;
  text-decoration: underline;
}

a:active {
  color: $color-primarylink;
  text-decoration: none;
}

img {
  vertical-align: middle;
  max-width: 100%;

  @include mq-mobile {
    // width: 100%;
  }
}

button,
select,
option,
input[type="text"],
input[type="num"],
input[type="tel"],
input[type="password"],
input[type="date"],
textarea {
  font-family: inherit;
}

input,
textarea,
button,
select,
option {
  -webkit-font-smoothing: antialiased !important;
}

strong {
  font-weight: 700;
}

video {
  background-color: #000;
}

canvas {}

::selection {
  // background: #e3007f;
}

::placeholder {
  color: #A2A2A2;
}


// js
// -----------------------------------------------------------------------
.js-sendFormBtn {
  cursor: pointer;
}