/*-----------------------------------------------------------------------
/ project - .p-illustList
----------------------------------------------------------------------- */

/* .p-illustList
----------------------------------------------------------------- */
.p-illustList {
  $root: &;


  &__title {
    text-align: center;
    font-weight: bold;

    @include mq-pc-tablet {
      margin-bottom: 35px;
      font-size: 28px;
    }

    @include mq-mobile {
      margin-bottom: vw(12);
      font-size: vw(20);
    }
  }

  &__list {
    @include mq-pc-tablet {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 20px;
    }

    @include mq-pc-large {
      justify-content: space-between;
    }

    @include mq-mobile {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &::before,
    &::after {
      order: 1;
      content: "";
      display: block;
    }

    &--center {
      justify-content: center;
      gap: 1.3%;
      margin: 0;

      &::before,
      &::after {
        display: none;
      }
    }
  }

  &__item,
  &__list::after,
  &__list::before {
    @include mq-pc-tablet {
      width: 100%;
      max-width: 31%;
      margin-bottom: 37px;
    }

    @include mq-pc-large {
      max-width: 24%;
    }

    @include mq-mobile {
      // width: 48%;
      margin-bottom: vw(24);
    }
  }


  &__item {
    transform: scale3d(1, 1, 1);
    transition: transform .3s ease;

    &:hover {
      @include mq-pc-tablet {
        transform: scale3d(1.08, 1.08, 1.08);
      }
    }

    a {
      display: inline-block;
    }
  }

  &__frame {
    position: relative;
    display: block;

    @include mq-pc-tablet {
      margin: 0 0 8px;
    }

    @include mq-mobile {
      margin: 0 0 vw(8);
    }

    img {
      // pointer-events: none;
    }

    .p-illustList__item.is-new &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
      background-image: url(../images/dogear-new.svg);
      background-repeat: no-repeat;
      pointer-events: none;
      overflow: hidden;

      @include mq-mobile {
        width: vw(40);
        height: vw(40);
      }
    }
  }

  &__ctlBtns {
    position: absolute;
    right: 8px;
    bottom: 8px;
    display: block;
    opacity: 0;
    transition: opacity .2s ease;

    @include mq-mobile {
      display: none;
    }

    #{$root}__item:hover & {
      opacity: 1;
    }
  }

  &__dl,
  &__copy {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-left: 4px;
    border: none;
    border-radius: 3px;
    background-color: #23B78F;
    background-position: center;
    background-repeat: no-repeat;
    appearance: none;
    vertical-align: middle;
    cursor: pointer;
    color: transparent;
    overflow: hidden;

    &:hover {
      background-color: #1F1E26;
    }
  }


  &__dl {
    background-image: url(../images/icon-download-white.svg);
  }

  &__copy {
    background-image: url(../images/icon-clipboard-white.svg);
  }

  &__thumbnail {
    cursor: pointer;
    user-select: none;
  }

  &__label {
    display: block;
    font-weight: bold;
    font-size: 18px;
  }
}