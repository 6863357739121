/*-----------------------------------------------------------------------
/ project - .p-illustSample
----------------------------------------------------------------------- */

/* .p-illustSample
----------------------------------------------------------------- */
.p-illustSample {
  display: flex;

  @include mq-pc-tablet {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0 20px;
    margin: 0 auto 120px;
    max-width: 1600px;
  }

  @include mq-mobile {
    flex-direction: column-reverse;
    margin-bottom: vw(56);
  }

  &__imgCol {
    @include mq-pc-tablet {
      width: 60%;
    }

    @include mq-mobile {
      margin-bottom: vw(20);
    }

    img {
      user-select: none;
    }
  }

  &__dataCol {
    @include mq-pc-tablet {
      width: 35%;
    }
  }

  &__title {
    font-weight: bold;

    @include mq-pc-tablet {
      font-size: 36px;
    }

    @include mq-mobile {
      margin-bottom: vw(8);
      text-align: center;
      font-size: vw(24);
    }
  }

  &__kana {
    @include mq-mobile {
      text-align: center;
      font-size: vw(12);
      margin-bottom: vw(16);
    }
  }

  &__btns {
    @include mq-pc-tablet {
      margin: 40px 0 8px;
    }

    .btns__col {
      margin: 8px 0;
    }
  }

  &__tags {
    .c-tagsList {
      @include mq-pc-tablet {
        justify-content: start;
      }
    }
  }
}


.p-illustSample-modal-gallery-button {
  display: none;
}