/*-----------------------------------------------------------------------
/ Utirity - br
----------------------------------------------------------------------- */
.u-br {
  &:after {
    content: "";
    display: block;
  }
}

.u-br-pc {
  @include mq-tablet {
    display: none;
  }

  @include mq-mobile {
    display: none;
  }

  // @include mq-pc{
  //   &:after{
  //     content:"";
  //     display: block;
  //   }
  // }
}

.u-br-tablet {
  @include mq-pc {
    display: none;
  }

  @include mq-mobile {
    display: none;
  }

  // @include mq-tablet{
  //   &:after{
  //     content:"";
  //     display: block;
  //   }
  // }
}

.u-br-pt {
  @include mq-mobile {
    display: none;
  }

  // @include mq-pc-tablet{
  //   &:after{
  //     content:"";
  //     display: block;
  //   }
  // }
}

.u-br-mobile {
  @include mq-pc {
    display: none;
  }

  @include mq-tablet {
    display: none;
  }

  // @include mq-mobile{
  //   &:after{
  //     content:"";
  //     display: block;
  //   }
  // }
}