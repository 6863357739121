/*-----------------------------------------------------------------------
/ project - pageHead
----------------------------------------------------------------------- */

/* p-pageHead
----------------------------------------------------------------- */
.p-pageHead {
  @include mq-pc-tablet {
    margin-bottom: 40px;
  }

  @include mq-mobile {
    margin-bottom: vw(30);
  }
}

.p-pageHead-path {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;

  @include mq-pc-tablet {
    font-size: 14px;
    text-align: center;
  }

  a {
    color: $color-black;
  }

  &__item {}

  &__item+&__item {
    &::before {
      content: "";
      display: inline-block;
      width: 7px;
      height: 10px;
      margin: 0 9px;
      background-image: url(../images/icon-path.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
}

.p-pageHead-title {
  text-align: center;
  font-weight: bold;

  @include mq-pc-tablet {
    margin-bottom: 8px;
    font-size: 32px;
  }

  @include mq-mobile {
    margin-bottom: vw(8);
    font-size: vw(24);
  }

  &--page {
    margin-top: 40px;
  }
}

.p-pageHead-talentName {
  text-align: center;
  margin: 24px 0;

  &__name {
    display: block;
    font-weight: bold;

    @include mq-pc-tablet {
      margin-bottom: 8px;
      font-size: 32px;
    }

    @include mq-mobile {
      margin-bottom: vw(8);
      font-size: vw(24);
    }
  }

  &__kana {
    display: block;

    @include mq-pc-tablet {
      font-size: 14px;
    }
  }
}

.p-pageHead-tags {
  margin: 18px 0;
}

.p-pageHead-cnt {
  text-align: center;
}