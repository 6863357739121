/*-----------------------------------------------------------------------
/ layout - page
----------------------------------------------------------------------- */

/* .l-page
----------------------------------------------------------------- */
.l-page {
	display: flex;
	flex-flow: column;
	position: relative;
	min-height: 100%;
}


/* .l-page-header
----------------------------------------------------------------- */
.l-page-header {}


/* .l-page-body
----------------------------------------------------------------- */
.l-page-body {
	flex: 1;
	background-color: #EFEFF0;

	&__inner {
		box-sizing: border-box;

		@include mq-pc-tablet {
			max-width: 1440px;
			margin: 0 auto;
			padding: 40px 20px;
		}

		@include mq-mobile {
			padding: vw(12) vw(12);
		}

		&--full {
			@include mq-pc-tablet {
				width: 100%;
				max-width: none;
			}
		}

		&--medium {
			@include mq-pc-tablet {
				max-width: 960px;
			}
		}

		&--article {
			@include mq-pc-tablet {
				max-width: 960px;
			}

			article {

				h2,
				h3,
				h4,
				h5,
				h6,
				p,
				ol,
				ul,
					{
					margin: 1em;
				}

				h2,
				h3,
				h4,
				h5,
				h6 {
					margin-top: 1.8em;
					font-weight: bold;
				}

				ul {
					list-style: disc;
				}

				ol {
					list-style: auto;
				}

				ul,
				ol {
					list-style-position: inside;
					margin-left: 2em;
					text-indent: -1em;
				}
			}
		}

		&--harf {
			@include mq-pc-tablet {
				max-width: 50%;
			}
		}

		&--min {
			@include mq-pc-tablet {
				max-width: 460px;
			}
		}
	}
}

/* .l-page-footer
----------------------------------------------------------------- */
.l-page-footer {
	background-color: #1F1E26;
	color: #fff;

	a {
		color: #fff;
	}
}


/* .l-page-notification
----------------------------------------------------------------- */
.l-page-notification {}