// -----------------------------------------------------------------
// Cpmponent - notification
// -----------------------------------------------------------------


/* .c-notification
----------------------------------------------------------------- */
.c-notification {
  position: fixed;
  z-index: 9999;
  display: inline-flex;
  align-items: center;
  background-color: #23B78F;
  color: #fff;
  font-weight: bold;
  line-height: 1;
  transition: opacity 1s ease;
  opacity: 0;

  &.is-show {
    opacity: 1;
  }

  @include mq-pc-tablet {
    bottom: 10px;
    left: 10px;
    padding: 9px 16px;
    font-size: 14px;
  }

  @include mq-mobile {
    justify-content: center;
    padding: vw(10) vw(8);
    right: vw(10);
    bottom: vw(10);
    left: vw(10);
    font-size: vw(14);
  }

  &::before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../images/icon-checkmark.svg);
  }

  &__label {}
}