// -----------------------------------------------------------------------
// variable
// -----------------------------------------------------------------------

// color
// -----------------------------------------------------------------------

$color-red: #BF2531;
$color-black: #1F1E26;
$color-green: #23B78F;
$color-gray:#EFEFF0;
$color-textBase: $color-black;
$color-background: #fff;
$color-primarylink: $color-red;

// font
// -----------------------------------------------------------------------
// $font-family-primary: "Helvetica Neue",
// Arial,
// "Hiragino Kaku Gothic ProN",
// "Hiragino Sans",
// Meiryo,
// sans-serif;


// $font-family-primary: -apple-system,
// BlinkMacSystemFont,
// Helvetica Neue,
// Segoe UI,
// Hiragino Kaku Gothic ProN,
// Hiragino Sans,
// ヒラギノ角ゴ ProN W3,
// Arial,
// メイリオ,
// Meiryo,
// sans-serif;

$font-family-primary: 'Noto Sans JP',
"Segoe UI",
"Helvetica Neue",
HelveticaNeue,
YuGothic,
"Yu Gothic Medium",
"Yu Gothic",
Verdana,
Meiryo,
sans-serif;


// max-width
// -----------------------------------------------------------------------
$pc-maxWidth: 1040px;


// mobile design size
// -----------------------------------------------------------------------
$mobileWidth: 750;


// break point
// 480/600/960/1280
// -----------------------------------------------------------------------
// $breakPoint-pc-max: 1280px;
// $breakPoint-pc-min: 961px;
// $breakPoint-tablet-max: 960px;
// $breakPoint-tablet-min: 736px;
// $breakPoint-mobile-max: 735px;
// $breakPoint-mobile-min: 320px;

$breakPoint-pc-max: 1280px;
$breakPoint-pc-min: 801px;
$breakPoint-tablet-max: 800px;
$breakPoint-tablet-min: 601px;
$breakPoint-mobile-max: 600px;
$breakPoint-mobile-min: 320px;