/*-----------------------------------------------------------------------
/ layout - login
----------------------------------------------------------------------- */

/* .l-login
----------------------------------------------------------------- */
.l-login {
  position: relative;

  @include mq-pc-tablet {
    width: 100%;
    height: 100%;
    display: flex;
  }

  &__main {
    padding: 0 0 20px;

    @include mq-pc-tablet {
      order: 2;
      width: 560px;
      overflow-y: scroll;
    }

    @include mq-mobile {
      padding: 0 vw(10);
    }
  }

  &__kv {
    @include mq-pc-tablet {
      order: 1;
      flex: 1;
    }

    @include mq-mobile {
      display: none;
    }
  }
}