// -----------------------------------------------------------------
// Foundation - sass custum
// -----------------------------------------------------------------

// メディアクエリ ショートカット
//--------------------------------------------------------
@mixin mq-pc-large {

  @media print,
  screen and (min-width: $breakPoint-pc-max) {
    @content;
  }
}

@mixin mq-pc {

  @media print,
  screen and (min-width: $breakPoint-pc-min) {
    @content;
  }
}

@mixin mq-pc-tablet {

  @media print,
  screen and (min-width: $breakPoint-tablet-min) {
    @content;
  }
}

@mixin mq-tablet {

  @media print,
  screen and (min-width: $breakPoint-tablet-min) and (max-width: $breakPoint-tablet-max) {
    @content;
  }
}

@mixin mq-tablet-mobile {

  @media print,
  screen and (max-width: $breakPoint-tablet-max) {
    @content;
  }
}

@mixin mq-mobile {

  @media print,
  screen and (max-width: $breakPoint-mobile-max) {
    @content;
  }
}

@mixin mq-print {
  @media print {
    @content;
  }
}


@function vw($pxSize: 16, $mobileWidth: 375) {
  @return math.div($pxSize, $mobileWidth) * 100+vw;
  // @return $pxSize / $mobileWidth * 100+vw;
}

@mixin fontSizeVw($fontSize: 16, $mobileWidth: 375) {
  font-size: math.div($fontSize, $mobileWidth * 100) + vw;
  // font-size: $fontSize / $mobileWidth * 100 + vw;
}