/*-----------------------------------------------------------------------
/ project - pagination
----------------------------------------------------------------------- */

/* p-pagination
----------------------------------------------------------------- */
.p-pagination {
  @include mq-pc-tablet {
    margin: 20px 0;
  }

  @include mq-mobile {
    margin: vw(20);
  }

  &__list {
    display: flex;
    justify-content: center;

    @include mq-pc-tablet {
      margin: 16px 0;
    }

    @include mq-mobile {
      margin: vw(16);
    }


    li {
      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 100%;
        background-color: #CDCDD3;
        background-repeat: no-repeat;
        background-position: center;
        color: $color-black;
        text-decoration: none;
        font-weight: bold;

        @include mq-pc-tablet {
          width: 40px;
          height: 40px;
          margin: 0 8px;
          font-size: 14px;
        }

        @include mq-mobile {
          width: 40px;
          height: 40px;
          margin: 0 4px;
          font-size: 14px;
        }

      }

      &.is-active a {
        mix-blend-mode: difference;
      }

      &.is-begin a {
        color: transparent;
        background-image: url(../images/icon-begin.svg);
      }

      &.is-last a {
        color: transparent;
        background-image: url(../images/icon-last.svg);
      }
    }

  }

  &__label {
    text-align: center;
  }
}