/*-----------------------------------------------------------------------
/ project - login
----------------------------------------------------------------------- */


/* p-login-board
----------------------------------------------------------------- */
.p-login-board {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 50px 0;

  @include mq-pc-tablet {
    max-width: 360px;
    margin: 0 auto;
  }

  &__logo {
    text-align: center;
    margin-bottom: 50px;

    img {
      height: 80px;
    }
  }

  &__title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
  }

  &__preText {
    text-align: center;
    margin-bottom: 40px;
    line-height: 1.75;
  }

  &__alertText {
    padding: 16px 0 26px;
    margin-bottom: 40px;
    text-align: center;
    line-height: 1.75;
    background-color: #FFF5F5;
    color: $color-red;
  }
}

/* p-login-form
----------------------------------------------------------------- */
.p-login-form {
  margin: 0 0 40px;

  &__row {
    margin: 16px 0;

    .row__label {
      margin-bottom: 6px;
      font-weight: bold;
    }

    .row__input {}

    .row__att {
      margin: 6px 0 0;
      color: #666;
      // font-size: 12px;
    }
  }

  &__cLink {
    font-weight: bold;
    text-align: center;
  }

  &__orRow {
    position: relative;
    margin: 24px 0;
    text-align: center;
    color: #A2A2A7;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      height: 1px;
      margin: auto;
      background-color: #A2A2A7;
    }

    &::before {
      right: calc(50% + 3em);
    }

    &::after {
      left: calc(50% + 3em);
    }
  }
}


/* p-login-kv
----------------------------------------------------------------- */
.p-login-kv {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #0F0D0D;
  background-image: url(../images/login-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;

  @include mq-tablet {}

  @include mq-pc-tablet {
    display: flex;
    align-items: center;
    justify-content: center;
    // font-size: 72px;
    font-size: 5vw;
    font-weight: bold;
    text-align: center;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    backdrop-filter: blur(2px);
  }

  &__copyText {
    position: relative;
    z-index: 2;
  }
}


/* p-login-footerNav
----------------------------------------------------------------- */
.p-login-footerNav {
  ul {
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    line-height: 1;

    li {
      padding: 0 7px;
    }

    li+li {
      border-left: solid 1px #000;
    }

    a {
      color: inherit;
    }

    @include mq-mobile {
      font-size: 12px;
      white-space: nowrap;
    }
  }
}


/* p-login-footer
----------------------------------------------------------------- */
.p-login-footer {
  padding: 20px 0;
  text-align: center;
  font-size: 12px;
}


/* wp user-registration
----------------------------------------------------------------- */
#user-registration.horizontal,
#user-registration {
  box-shadow: none !important;
  background: none !important;
  margin: 0 !important;
}

.user-registration-password-hint {
  display: none !important;
}

div.ur-frontend-form {
  margin-bottom: 0px;
  border: none;
  padding: 0px;
  box-sizing: border-box;
}


.ur-frontend-form {
  .btn.button.ur-submit-button {
    @extend .c-btn-primary;
    @extend .is-size--full;
  }

  .input-text {
    @extend .c-form-inputText;
    border-radius: 4px !important;
  }

  .description {
    margin-top: .8em;
  }


  input.ur-input-border-green,
  textarea.ur-input-border-green {
    border-color: inherit !important;
  }
}