// -----------------------------------------------------------------
// Cpmponent - forom
// -----------------------------------------------------------------

/* .c-form-inputText
----------------------------------------------------------------- */
.c-form-inputText {
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  appearance: none;
  border: solid 1px #CDCDD3;
  border-radius: 4px;
  width: 100%;
  height: 48px;
  padding: 0 .5em;
  font-size: 16px;

  &.is-error {
    background-color: lighten($color-red, 50%);
    border-color: $color-red;
  }
}

.c-form-checkboxLine {
  display: flex;
  align-items: flex-start;

  &__col {
    // display: block;

    &+& {
      margin-left: 5px;
    }
  }
}

.c-form-checkbox {
  position: relative;
  width: 24px;
  height: 24px;
  border: 1px solid $color-black;
  border-radius: 4px;
  vertical-align: middle;
  appearance: none;
  cursor: pointer;

  &:checked {
    border-color: $color-red;
    background-color: $color-red;
  }

  &:checked:before {
    position: absolute;
    top: 1px;
    left: 6px;
    transform: rotate(50deg);
    width: 8px;
    height: 14px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    content: '';
  }
}


/* .c-form-errorList
----------------------------------------------------------------- */
.c-form-errorList {
  padding: .5em .25em;
  border: solid 2px $color-red;
  border-radius: 4px;
  background-color: lighten($color-red, 50%);
  color: $color-red;
  font-weight: bold;

  li {
    margin-left: 1em;
    text-indent: -1em;

    &::before {
      content: "・";
    }
  }
}