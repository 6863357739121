// -----------------------------------------------------------------
// Cpmponent - tagsList
// -----------------------------------------------------------------


/* .c-tagsList
----------------------------------------------------------------- */
.c-tagsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 18px 0;
  gap: 8px 8px;

  &__item {

    a,
    span {
      display: inline-block;
      line-break: 1;
      color: $color-black;
      text-decoration: none;
      border: solid 1px $color-black;
      border-radius: 5em;
      padding: 0.3em 1em;

      &:hover {
        background-color: #fff;
      }
    }
  }
}