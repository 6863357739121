// -----------------------------------------------------------------
// Cpmponent - btn
// -----------------------------------------------------------------
%c-btn-tpl {
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  padding: 1.1em 0.5em;
  line-height: 1;
  border: none;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
  vertical-align: middle;
  white-space: nowrap;
  user-select: none;
  // outline: none;
  appearance: none;
  cursor: pointer;
  letter-spacing: 0.05em;

  @include mq-pc-tablet {
    width: 300px;
  }

  &:link,
  &:visited,
  &:hover,
  &:active {
    color: inherit;
    text-decoration: none;
    text-indent: 0;
  }

  &:disabled,
  &.is-disabled {
    opacity: 0.7;
    pointer-events: none;

    &:link,
    &:hover,
    &:hover,
    &:active {
      color: inherit;
      text-decoration: none;
      text-indent: 0;
    }
  }

  &.is-size--full {
    width: 100%;
  }
}


/* .c-btn-primary
----------------------------------------------------------------- */
.c-btn-primary {
  @extend %c-btn-tpl;
  $color: $color-red;
  position: relative;
  color: #fff;
  background-color: $color;
  border: solid 1px $color;
  text-align: center;
  font-weight: 700;
  transition: all 0.2s ease;

  &:link,
  &:visited,
  &:hover,
  &:active {
    color: #fff;
    // color: inherit;
  }

  &:hover {
    background-color: darken($color, 10%);
  }
}

/* .c-btn-secondary
----------------------------------------------------------------- */
.c-btn-secondary {
  @extend %c-btn-tpl;
  $color: #fff;
  position: relative;
  color: $color-red;
  background-color: $color;
  border: solid 2px $color-red;
  text-align: center;
  font-weight: 700;
  transition: all 0.2s ease;

  &:link,
  &:visited,
  &:hover,
  &:active {}

  &:hover {
    background-color: #FFEFF0;
  }
}

/* .c-btn-contactMail
----------------------------------------------------------------- */
.c-btn-contactMail {
  @extend %c-btn-tpl;
  $color: $color-red;

  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
  width: auto;
  color: $color;
  background-color: transparent;
  border: solid 3px $color-red;
  text-align: center;
  font-weight: 700;
  transition: all 0.2s ease;

  @include mq-pc-tablet {
    // min-width: 206px;
    height: 48px;
    padding: 0 19px;
  }

  &::before {
    display: block;
    content: "";
    background-image: url(../images/icon-mail-red.svg);
    background-repeat: no-repeat;
    width: 20px;
    height: 16px;
    margin-right: 8px;
    margin-bottom: -2px;
  }

  &:link,
  &:visited,
  &:hover,
  &:active {}

  &:hover {
    background-color: #FFEFF0;
  }

  .label {
    // display: block;
  }
}

/* .c-btn-ctl
----------------------------------------------------------------- */
.c-btn-ctl {
  @extend %c-btn-tpl;
  $color: $color-green;

  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
  width: 100%;
  color: $color;
  background-color: #fff;
  border: solid 3px $color-green;
  text-align: center;
  font-weight: 700;
  transition: all 0.2s ease;

  @include mq-pc-tablet {
    // min-width: 206px;
    height: 48px;
    padding: 0 19px;
  }

  &::before {
    content: "";
    display: block;
    // width: 20px;
    // height: 16px;
    margin-right: 8px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  &:link,
  &:visited,
  &:hover,
  &:active {}

  &:hover {
    background-color: $color;
    color: #fff;

    &::before {}
  }

  .label {
    // display: block;
  }

  &--dl {
    &::before {
      width: 20px;
      height: 20px;
      margin-bottom: -2px;
      background-image: url(../images/icon-download-green.svg);
    }

    &:hover::before {
      background-image: url(../images/icon-download-white.svg);
    }
  }

  &--clipboard {
    &::before {
      width: 20px;
      height: 24px;
      margin-bottom: -1px;
      background-image: url(../images/icon-clipboard-green.svg);
    }

    &:hover::before {
      background-image: url(../images/icon-clipboard-white.svg);
    }
  }
}

/* .c-btn-contactMail-footer
----------------------------------------------------------------- */
.c-btn-contactMail-footer {
  @extend %c-btn-tpl;
  $color: #fff;

  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
  width: auto;
  color: $color;
  background-color: transparent;
  border: solid 3px $color;
  text-align: center;
  font-weight: 700;
  transition: all 0.2s ease;

  @include mq-pc-tablet {
    padding: 18px 40px;
    font-size: 18px;
  }

  &::before {
    display: block;
    content: "";
    background-image: url(../images/icon-mail-white.svg);
    background-repeat: no-repeat;
    width: 20px;
    height: 16px;
    margin-right: 8px;
    margin-bottom: -2px;
  }

  &:link,
  &:visited,
  &:hover,
  &:active {}

  &:hover {
    background-color: rgba(#fff, 0.1);
  }

  .label {
    // display: block;
  }
}