/*-----------------------------------------------------------------------
/ project - faq
----------------------------------------------------------------------- */

/* p-faq-wrap
----------------------------------------------------------------- */
.p-faq-wrap {}


/* p-faq-box
----------------------------------------------------------------- */
.p-faq-box {
  line-height: 1.75;
  margin-bottom: 4em;

  &__head {
    margin: 0 0 1em;

    @include mq-pc-tablet {
      font-size: 24px;
    }

    @include mq-mobile {
      font-size: vw(20);
    }
  }

  &__body {
    @include mq-pc-tablet {
      font-size: 16px;
    }

    @include mq-mobile {
      font-size: vw(16);
    }
  }
}